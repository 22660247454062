import '@/styles/main.scss' // breakpoints from 

import { createSSRApp } from 'vue'

import App from './App.vue'

import createStore from './plugins/store'
import createRouter from './plugins/router'

import routes from './plugins/router/routes'
import createM11n from 'm11n'

import portal from './modules/portal'

import locales from './plugins/i18n/locales'
import createI18n from './plugins/i18n'

import Foundation from '@/libs/foundation'
import icons from '@/assets/icons'

export default function createApp () {
  const app = createSSRApp(App)
  
  const m11n = createM11n(routes, locales)

  m11n.use(portal)

  const store = createStore()
  const router = createRouter(m11n.getRoutes())
  const i18n = createI18n(m11n.getLocales())

  app.use(store)
  app.use(router)
  app.use(i18n)

  const context = { store, router }
  app.use(m11n, { context })
  
  Foundation({ icons })

  return {
    app, store, router
  }
}
