import createApp from './app'

import '@/services/registerServiceWorker'

const { app, store, router } = createApp({
  
})

const storeInitialState = window.__INITIAL_STATE__
if (storeInitialState) {
  store.replaceState(storeInitialState)
}

router.isReady().then(() => {
  app.mount('#app', true)
})
