<template>
  <div :class="[$options.name, { active }]" ref="container">
    <touch class="toggle" @tap="toggle">{{ locale }}</touch>
    <ul class="locales" >
      <touch v-for="(item, index) in locales" :key="item + index"
        class="item" tag="li" @tap="change(item)">
        {{ item }}
      </touch>
    </ul>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'

  import { useI18n } from 'i18n'
  
  import Touch from '@/libs/foundation/components/Touch'

  import useToggle from '@/libs/foundation/composables/useToggle'
  import useOnClickOutside from '@/libs/foundation/composables/useOnClickOutside'

  const isClient = window !== undefined

  export default {
    name: 'locale-select',
    components: {
      Touch
    },
    setup () {
      const { active, toggle } = useToggle()

      const { locale, availableLocales } = useI18n()
      
      const locales = computed(() => availableLocales.filter(item => item !== locale.value))

      function change (l) {
        toggle(false)
        locale.value = l

        if (isClient) window.localStorage.setItem('lang', l)
      }

      const container = ref(null)

      useOnClickOutside(container, active, () => {
        toggle(false)
      })

      if (isClient) {
        const saved = window.localStorage.getItem('lang')

        if (saved) {
          locale.value = saved
        }
      }

      return {
        active, toggle,
        locale, locales, change,
        container
      }
    }
  }
</script>

<style lang="scss" scoped>
  .locale-select {
    --shadow: hsla(0deg, 0%, 0%, 5%);
  }

  .locale-select {
    position: relative;

    .toggle {
      padding: calc(var(--spacing) / 3) 0;
      width: 50px;
      margin: 0 0 0 calc(var(--gutter) / 3);
      border: thin solid var(--tertiary-light);
      border-radius: calc(var(--corner-width) / 4);

      &:hover {
        background: var(--tertiary-25);
      }
    }

    .locales {
      width: calc(100% - calc(var(--gutter) / 3));
      position: absolute;
      bottom: calc(var(--gutter) / -4);
      left: calc(var(--gutter) / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      background: var(--light);
      box-shadow: 0px 3px 14px 3px var(--shadow);
      border-radius: calc(var(--corner-width) / 4);
      pointer-events: none;
      opacity: 0;
      transform: translate3d(0, 0, 0);
      transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);

      .item {
        padding: calc(var(--spacing) / 3) calc(var(--spacing) / 1.25);

        &:hover {
          background: var(--tertiary-light);
          border-radius: calc(var(--corner-width) / 4);
        }
      }
    }

    .toggle, .locales .item {
      text-transform: uppercase;
      font-size: calc(var(--font-size) * 0.75);
      font-family: 'sf-pro-text-light';
      color: var(--tertiary-darker);
    }

    &.active {
      .locales {
        pointer-events: all;
        opacity: 1;
        transform: translate3d(0, 100%, 0);
      }
    }
  }
</style>
