<template>
  <touch :class="[$options.name]" @tap="click">
    <slot/>
  </touch>
</template>

<script>
  import Touch from './Touch'

  import useShare from '@/libs/foundation/composables/useShare'

  import capitalize from '@/libs/foundation/helpers/capitalize'

  export default {
    name: 'share',
    components: {
      Touch
    },
    props: {
      title: { type: String, default: window && window?.location?.hostname },
      text: { type: String, default: document && document?.title },
      url: { type: String, default: window && window?.location?.href }
    },
    setup (props) {
      const { isSupported, share } = useShare({
        title: capitalize(props.title),
        text: capitalize(props.text),
        url: props.url
      })

      function click () {
        if (isSupported) {
          share()
        }
      }

      return {
        click
      }
    }
  }
</script>

<style lang="scss" scoped>
  .share {
    // Define css custom-properties here.
  }

  .share {

  }
</style>
