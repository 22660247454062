import { unref } from 'vue'

export default function useShare (shareOptions = {}, options = {}) {
  const { navigator = window && window.navigator } = options

  const isSupported = navigator && 'canShare' in navigator

  const share = async (overrideOptions = {}) => {
    if (isSupported) {
      const data = {
        ...unref(shareOptions),
        ...unref(overrideOptions)
      }

      let granted = true

      if (data.files && navigator.canShare)
        granted = navigator.canShare({ files: data.files })

      if (granted) return navigator?.share(data)
    }
  }

  return {
    isSupported,
    share
  }
}