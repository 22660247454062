import { createI18n } from 'i18n'

import defaultLocales from './locales'
import { datetimes, numbers } from './formats'

export default function (locales = defaultLocales) {
  const locale = getUserLang()

  return createI18n({
    legacy: false,
    locale: locale.langNoISO,
    fallbackLocale: 'en',
    messages: locales,
    datetimeFormats: datetimes,
    numberFormats: numbers
  })
}

function getUserLang () {
  const lang = window.navigator.language || window.navigator.userLanguage || 'en'
  
  return {
    lang: lang,
    langNoISO: lang.split('-')[0]
  }
}