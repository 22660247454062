import stores from './stores'
import routes from './routes'
import locales from './locales'
// import { http, socket } from './api'

const name = 'portal'
const version = '0.1.0'

export default {
  name,
  version,
  routes,
  locales,
  register ({ store }) {
    store.registerModule(name, stores(name, version))

    // httpClient.registerModule(http)
    // socketClient.registerModule(socket)
  }
}
