import { onMounted, onUnmounted } from 'vue'

export default function useOnClickOutside (target, condition, callback, elements = []) {
  const app = document.querySelector('#app') || document.body  

  function onClick (e) {
    if (!e || condition.value === false) return

    if (('isTrusted' in e && !e.isTrusted) ||
      ('pointerType' in e && !e.pointerType)
    ) return

    elements.push(target.value)

    !elements.some(el => el.contains(e.target)) && setTimeout(() => {
      callback()
    }, 0)
  }  

  onMounted(() => {
    app.addEventListener('click', onClick, true)
  })
    
  onUnmounted(() => {
    app.removeEventListener('click', onClick, true)
  })
}