import { ref } from 'vue'

import useEventListener from './useEventListener'

export default function useScroll ({ target = window } = {}) {
  if (!target) {
    return {
      x: ref(0),
      y: ref(0)
    }
  }

  const x = ref(target.pageXOffset)
  const y = ref(target.pageYOffset)

  useEventListener(target, 'scroll', () => {
    x.value = target?.pageXOffset ?? target?.value?.scrollLeft
    y.value = target?.pageYOffset ?? target?.value?.scrollTop
  }, {
    capture: false,
    passive: true
  })

  return { x, y };
}