// import { api as http } from '../api/http'
// import { api as socket } from '../api/socket'

// import { } from './types/actions'
// import { } from './types/mutations'

export default function (MODULE_NAME, VERSION) {
  const init = () => ({
    module: MODULE_NAME,
    version: VERSION
  })

  const state = init()

  const getters = {}

  const actions = {}

  const mutations = {}

  const watchers = []

  return {
    namespaced: true,
    init,
    state,
    getters,
    actions,
    mutations,
    watchers
  }
}
