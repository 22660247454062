<template>
  <nav :class="[$options.name]">
    <touch class="item" v-for="(link, index) in links" :key="index" @tap="scrollTo(link.to)">
      {{ link.label }}
    </touch>
  </nav>
</template>

<script>
  import Touch from '@/libs/foundation/components/Touch'

  export default {
    name: 'navigation',
    props: {
      links: { type: Array, default: () => [] }
    },
    components: {
      Touch
    },
    setup () {
      function scrollTo (to) {
        const el = document.querySelector(`${to}`)

        el.scrollIntoView()
      }

      return {
        scrollTo
      }
    }
  }
</script>

<style lang="scss" scoped>
  .navigation {
    --display: none;

    @include from ('md') {
      --display: block;
    }
  }

  .navigation {
    display: var(--display);

    .item {
      color: var(--dark);
      padding: var(--spacing) 0;
      margin: 0 calc(var(--gutter) * 2); 

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
