import Root from '@/components/layout/Root'

export default [
  {
    path: '/',
    name: 'root',
    component: Root,
    children: []
  },
  { path: '/:pathMatch(.*)*', redirect: '/' }
]
