<template>
  <svg 
    :class="[$options.name]" 
    version="1.1" 
    :role="role" :aria-label="label" 
    :x="x" :y="y" :width="width" :height="height" 
    :viewBox="box" :style="style"
  >
    <slot>
      <template v-if="icon && icon.paths">
        <path v-for="(path, index) in icon.paths" v-bind="path" :key="index"/>
      </template>
      <template v-if="icon && icon.polygons">
        <polygon v-for="(polygon, index) in icon.polygons" v-bind="polygon" :key="index"/>
      </template>
      <template v-if="icon && icon.raw"><g v-html="icon.raw"></g></template>
    </slot>
  </svg>
</template>

<script>
import { reactive, computed, toRefs } from 'vue'

import assign from '@/libs/foundation/helpers/assign'

let icons = {}

export default {
  name: 'icon',
  props: {
    name: {
      type: String,
      validator (val) {
        if (val && !(val in icons)) {
          console.warn(`Invalid prop: prop "name" is referring to an unregistered icon "${val}".\nPlease make sure you have imported this icon before using it.`)
          return false
        }
        return true
      }
    },
    label: { type: String, default: null },
    scale: { type: [Number, String], default: 1 }
  },
  setup (props) {
    const state = reactive({
      x: 0,
      y: 0,
      childrenWidth: 0,
      childrenHeight: 0,
      outerScale: 1,
      role: props.label ? 'img' : 'presentation'
    })

    const icon = computed(() => {
      if (props.name) {
        return icons[props.name]
      }
      return null
    })

    const ratio = computed(() => {
     if (!icon) {
        return 1
      }
      let { width, height } = icon.value
      return Math.max(width, height) / 16 // 16 => --base-line-height
    })
    
    const normalizedScale = computed(() => {
      let scale = props.scale
      scale = typeof scale === 'undefined' ? 1 : Number(scale)
      if (isNaN(scale) || scale <= 0) {
        console.warn(`Invalid prop: prop 'scale' should be a number over 0.`, props.scale)
        return state.outerScale
      }
      return scale * state.outerScale
    })

    const style = computed(() => {
      if (normalizedScale.value === 1) {
        return false
      }
      return {
        fontSize: normalizedScale.value + 'em'
      }
    })

    const width = computed(() => {
      return (state.childrenWidth.value || icon.value) && icon.value.width / (ratio.value * normalizedScale.value || 0)
    })

    const height = computed(() => {
      return (state.childrenHeight.value || icon.value) && icon.value.height / (ratio.value * normalizedScale.value || 0)
    })

    const box = computed(() => {
      if (icon.value) {
        return `0 0 ${icon.value.width} ${icon.value.height}`
      }
      return `0 0 ${width.value || 20} ${height.value || 20}`
    })

    /*
    onMounted(() => {
      if (icon) {
        return
      }
      children.forEach(child => {
        child.outerScale = normalizedScale
      })
      let width = 0
      let height = 0
      children.forEach(child => {
        width = Math.max(width, child.width)
        height = Math.max(height, child.height)
      })
      state.childrenWidth = width
      state.childrenHeight = height
      children.forEach(child => {
        child.x = (width - child.width) / 2
        child.y = (height - child.height) / 2
      })
    })
    */

    return {
      ...toRefs(state),
      icon,
      style,
      width,
      height,
      box
    }
  },
  register (data) {
    for (let name in data) {
      let icon = data[name]
      let {
        paths = [],
        d,
        polygons = [],
        points
      } = icon
      if (d) {
        paths.push({ d })
      }
      if (points) {
        polygons.push({ points })
      }
      icons[name] = assign({}, icon, {
        paths,
        polygons
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon {
    --icon-fill: transparent;
    --icon-stroke: currentColor;
    --icon-stroke-width: 1px;
  }

  .icon {
    display: inline-block;
    fill: var(--icon-fill);
    stroke: var(--icon-stroke);
    stroke-width: var(--icon-stroke-width);
    stroke-linecap: round;
    stroke-linejoin: round;
  }
</style>