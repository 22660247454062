import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

import defaultRoutes from './routes'

const isServer = typeof window === 'undefined'
const history = isServer ? createMemoryHistory() : createWebHistory()

export default function (routes = defaultRoutes) {  
  return createRouter({ 
    routes, 
    history
  })
}
