<template>
  <div ref="root" :class="[$options.name]">
    <topbar/>
    <div class="container">
      <router-view v-bind="{ x, y }"/>
    </div>
    <bottombar v-bind="{ locales }"/>
  </div>
</template>

<script>
  import { ref, computed } from 'vue'

  import { useI18n } from 'i18n'

  import Topbar from './Topbar'
  import Bottombar from './Bottombar'

  import useScroll from '@/libs/foundation/composables/useScroll'

  export default {
    name: 'root',
    components: {
      Topbar,
      Bottombar
    },
    setup () {
      const root = ref(null)

      const { x, y } = useScroll({ target: root })

      const { t } = useI18n()

      const locales = computed(() => ({
        copyright: t('copyright') 
      }))
  
      return {
        root,
        x, y,
        locales
      }
    }
  }
</script>

<style lang="scss" scoped>
  .root {
    // Define css custom-properties here.
  }

  .root {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 5px;
      height: 0px;
      background-color: var(--tertiary);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--tertiary-darker);
    }
  }
</style>
