<template>
  <component :class="[$options.name]" :is="tag" v-on="listeners">
    <slot/>
  </component>
</template>

<script>
  import { computed } from 'vue'

  export default {
    name: 'touch',
    props: {
      tag: { type: String, default: 'button' }
    },
    setup (_, context) {
      const noop = () => {}

      const listeners = computed(() => {
        const events = {}

        events.click = context.attrs.onTap || noop

        return events
      })

      /*
      const map = {
        onTap: 'click'
      }

      const listeners = computed(() => {
        let keys = Object.keys(map)
        let events = {}
        console.log('updated')
        for (let key of keys) {
          if (context.attrs[key]) events[map[key]] = context.attrs[key]
        }

        return events
      })
      */
     
      return {
        listeners
      }
    }
  }
</script>

<style lang="scss" scoped>
  .touch {
    // Define css custom-properties here.
  }

  .touch {
    cursor: pointer;
  }
</style>
