<template>
  <footer :class="[$options.name]">
    <div class="copyright">
      <ul class="list">
        <li class="item">
          <router-link class="logo" to="/">
            <img src="@/assets/images/logo.svg" alt="Spiitz"/>
          </router-link>
        </li>
        <li class="item copy">{{ locales.copyright }}</li>
      </ul>
    </div>
    <div class="networks">
      <ul class="list">
        <li class="item">
          <a class="twitter" href="https://twitter.com/spiitzOff" target="_blank"><icon name="twitter" scale="1.1"/> {{ t(twitter) }}</a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
  import Icon from '@/libs/foundation/components/Icon'

  import { useI18n } from 'i18n'

  export default {
    name: 'bottombar',
    components: {
      Icon
    },
    props: {
      locales: { type: Object, default: () => ({}) }
    },
    setup () {
      const { t } = useI18n()

      const copyright = '© 2021 Spiitz. Tous droits réservés'
      const twitter = 'Twitter'
      const discover = 'Découvrez Spiitz'
      const how = 'Comment ça marche ?'
      const you = 'Lieux privatif'
      const about = 'À propos'
      const cgu = `Conditions d'utilisation`
      const privaty = 'Politique de confidentialité'
      const legal = 'Mentions légales'
      const help = 'Aide'
      const center = `Centre d'aide`
      const contact = 'Nous contacter'

      return {
        t,
        copyright, twitter,
        discover, how, you,
        about, cgu, privaty, legal,
        help, center, contact
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bottombar {
    --height: 120px;
    --padding: 0 var(--gutter);

    @include from ('md') {
      --padding: calc(var(--gutter) * 2) 10% 0 10%;
    }
  }

  .bottombar {
    width: 100%;
    height: var(--height);
    padding: 0 var(--gutter);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: hsl(27deg, 43%, 96%);

    a {
      color: var(--dark-lighter);
    }

    .copyright {
      .list {
        .item {
          &.copy {
            font-size: calc(var(--font-size) * 0.8);
            color: var(--tertiary-dark);
            margin: calc(var(--gutter) / 2) 0 0 0;
          }
          &:last-child {
            font-weight: 500;
            font-family: 'sf-pro-text-medium';
            margin: calc(var(--gutter) / 2) 0 0 0;
          }
        }
      }
    }

    .networks {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 auto;

      .list {
        .item {
          &:last-child {
            font-weight: 500;
            font-family: 'sf-pro-text-medium';
          }
        }
      }

      .twitter {
        color: #00CDFF;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .icon {
        stroke-width: 2px;
        fill: #00CDFF;
        margin: 0 calc(var(--gutter) / 4) 0 0;
      }
    }
  }
</style>
