import { unref, watch, onUnmounted } from 'vue'

const isString = val => typeof val === 'string'
const noop = () => {}

export default function useEventListener (...args) {
  let target
  let event
  let listener
  let options

  if (isString(args[0])) {
    [event, listener, options] = args
    target = window
  } else {
    [target, event, listener, options] = args
  }

  if (!target) return noop

  let cleanup = noop

  const stopWatch = watch(() => unref(target), (el) => {
    cleanup()

    if (!el) return

    el.addEventListener(event, listener, options)

    cleanup = () => {
      el.removeEventListener(event, listener, options)
      cleanup = noop
    }
  }, { immediate: true, flush: 'post' })

  const stop = () => {
    stopWatch()
    cleanup()
  }

  onUnmounted(stop)

  return stop
}