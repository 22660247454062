<template>
  <header :class="[$options.name]">
    <router-link class="logo" to="/about">
      <img src="@/assets/images/logo.svg" alt="Spiitz"/>
    </router-link>
    <!--<navigation v-bind="{ links }"/>-->
    <share v-bind="{ ...share }"><icon name="share"/></share>
    <locale-select/>
  </header>
</template>

<script>
  import Icon from '@/libs/foundation/components/Icon'
  import Share from '@/libs/foundation/components/Share'
  import LocaleSelect from '@/libs/foundation/components/LocaleSelect'

  import Navigation from '@/components/navigation/Navigation'

  export default {
    name: 'topbar',
    components: {
      Icon,
      Share,
      LocaleSelect,
      Navigation
    },
    setup () {
      const links = [
        { label: 'Fonctionnalités', to: '#features' },
        { label: 'Revues', to: 'reviews' },
        { label: 'Manifeste', to: 'manifest' }
      ]

      const share = {
        title: 'Spiitz',
        text: 'Join Spiitz',
        url: 'https://spiitz.com'
      }

      return {
        links,
        share
      }
    }
  }
</script>

<style lang="scss" scoped>
  .topbar {
    --height: 60px;
    --padding: 0 var(--gutter);

    @include from ('md') {
      --height: 100px;
      --padding: 0 10%;
    }
  }

  .topbar {
    width: 100%;
    height: var(--height);
    padding: var(--padding);
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;

    .logo {
      margin: 0 auto 0 0;
    }

    .share {
      display: inline-flex;
      margin: 0 0 0 auto;
      padding: calc(var(--spacing) / 2);
      color: var(--tertiary);
    }
  }
</style>
