import { readonly, ref } from 'vue'

const whitelist = [true, false]

export default function useToggle (initial = false) {
  const active = ref(initial)

  function toggle (value) {
    active.value = whitelist.includes(value) ? value : !active.value
  }

  return {
    active: readonly(active),
    toggle
  }
}